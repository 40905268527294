const styles = theme => ({
  coloredIcon: {
    color: theme.palette.secondary.main,
    padding: '0px 10px',
    fontSize: '1.2rem',
  },
  iconButton: {
    height: '1.5rem',
    width: '1.5rem',
  }
});

export default styles;
